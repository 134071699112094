export const projectData = [
  {
    id: 1,
    title: "Admin Dashboard",
    desc: "- Sidebar, Navbar, users page, products page, CRUD Ops for User/Products<br/>- Reusable Table component with Pagination, Serverless API<br/>- Authentication, Loading Skeleton, Cloud MongoDB Database, Recap Chart<br/>- Technology : Next Js, Typescript, Next-Auth, Framer Motion, SASS",
    liveUrl: "https://admin-dashboard-as5b.vercel.app/",
    githubUrl: "https://github.com/v1v3k-nepal/admin-dashboard",
    bgImg: "/assets/projects/admin-dashboard.png",
    techUsed: ["Next Js", "Typescript", "Next-Auth", "Framer Motion", "SASS"],
  },
  {
    id: 2,
    title: "Dream Market",
    desc: "- Khalti Payment Gateway Integration using serverless API in Next JS<br/>- User can see products, product details, add to cart, remove from cart, search products- Completely responsive (all devices), proper form validation at shipping page.<br/>- Technology: Next JS, Tailwind CSS, Redux-toolkit, Yup, Formik",
    liveUrl: "https://your-dream-market.vercel.app/",
    githubUrl: "https://github.com/v1v3k-nepal/Dream-Market",
    bgImg: "/assets/projects/work-2.png",
    techUsed: ["Next JS", "Tailwind CSS", "Redux-toolkit", "Yup", "Formik"],
  },
  {
    id: 3,
    title: "Agency WebApp",
    desc: "- User Authentication, Protected Routes, Google-OAuth, Pwd Validation<br/>,- Portfolio Page, All CRUD Ops for Blogs, Cloud Mongo-Database<br/>- Created own serverless API to provide blog posts from own database- Technology : Next JS, Tailwind CSS, MongoDB(Cloud), Next-Auth",
    liveUrl: "https://agency-webapp.vercel.app/",
    githubUrl: "https://github.com/v1v3k-nepal/agency-webapp",
    bgImg: "/assets/projects/work-3.png",
    techUsed: ["Next JS", "Tailwind CSS", "MongoDB(Cloud)", "Next-Auth"],
  },
  {
    id: 4,
    title: "Company Portfolio",
    desc: "- DropDown Modal, Elegant Hero Section, Footer with banner image & gradient,<br/>- Reusable Cards, Contact Form, Completely Responsive<br/>- Technology : Next Js, Javascript, SASS",
    liveUrl: "https://yipl-frontend-challenge-vivek.vercel.app/",
    githubUrl: "https://github.com/v1v3k-nepal/catalog-webapp-yipl",
    bgImg: "/assets/projects/work-1.png",
    techUsed: [" Next Js", "Javascript", "SASS"],
  },
  {
    id: 5,
    title: "Contact App",
    desc: "-User can Add,Update, Delete Contacts<br/>- Toggle your Favourite Contacts, live search,<br/>-Completely Responsive",
    liveUrl: "https://v1v3k-nepal.github.io/contact-app",
    githubUrl: "https://github.com/v1v3k-nepal/contact-app",
    bgImg: "/assets/projects/work-2.png",
    techUsed: ["React", "Javascript", "HTML", "CSS"],
  },
  {
    id: 6,
    title: "Todos List",
    desc: "- user can add, update and delete todos,<br/> -feature to mark as completed",
    liveUrl: "https://v1v3k-nepal.github.io/todos-list/",
    githubUrl: "https://github.com/v1v3k-nepal/todos-list",
    bgImg: "/assets/projects/work-3.png",
    techUsed: ["React", "Javascript", "HTML", "CSS"],
  },
  {
    id: 7,
    title: "Weather App",
    desc: "- User can search city name, handles error if city not found<br/>- Info : Short description, temp, max temp, min temp, sunrise, sunset time<br/>- Technology : Html, CSS, JavaScript",
    liveUrl: "https://v1v3k-nepal.github.io/weather-app",
    githubUrl: "https://github.com/v1v3k-nepal/Dream-Market",
    bgImg: "/assets/projects/work-1.png",
    techUsed: ["HTML", "CSS", "Javascript"],
  },
  {
    id: 8,
    title: "DishHome DTH Remote Android App",
    desc: "It's an Android IR Remote App for DishHome DTH. I have coded it's UI entirely in HTML, CSS & JS, & used Java for the Backend",
    liveUrl:
      "https://play.google.com/store/apps/details?id=com.vivek.dishhomedthremote",
    githubUrl: "https://github.com/v1v3k-nepal/DishHome-DTH-Remote",
    bgImg: "/assets/projects/work-2.png",
    techUsed: ["HTML", "CSS", "Javascript", "Java"],
  },
  {
    id: 9,
    title: "DishHome IPTV Remote Android App",
    desc: "It's an Android IR Remote App for DishHome IPTV. I have coded it's UI entirely in HTML, CSS & JS, & used Java for the Backend.",
    liveUrl:
      "https://play.google.com/store/apps/details?id=com.vivek.dhitvremote",
    githubUrl: "https://github.com/v1v3k-nepal/DishHome-IPTV-Remote",
    bgImg: "/assets/projects/work-3.png",
    techUsed: ["HTML", "CSS", "Javascript", "Java"],
  },
];
