import './App.scss';
import Home from "./components/Home"

import React from 'react'

const App = () => {
  return (
    <div>
      <Home/>
    </div>
  )
}

export default App;
